import { useCallback, useMemo, useState } from "react";
import { Clear as ClearIcon } from "@mui/icons-material";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Typography } from "antd";

import DatePicker from "../../../admin-ui/components/DatePicker";

export type SearchWithDateTypeOption<T extends string | number> = {
  label: string;
  value: T;
};

/**
 *
 * 기존 useSearchWithDate와 다른 점
 * 내부에 useEffect를 없애서 새로고침시 값을 ""로 바꾸는 걸 방지하고
 * endDate가 startDate에 종속되지 않게 하고
 * defaultValue를 설정할 수 있게 setState를 hook 밖으로 리턴해주고 있습니다.
 * style도 약간 우측 정렬로 바뀌었습니다.
 * history나 로컬에 검색 내역을 저장하고 싶을때 useSearchWithDate 대신 쓸 수 있습니다.
 */
export default function useSearchWithDateWithNoUseEffect<
  T extends string | number
>({
  dateSearchTypeOptions,
  startLabel,
  endLabel,
  key = "adminBidFilter",
  defaultValue,
  dateHistory,
}: {
  dateSearchTypeOptions: SearchWithDateTypeOption<T>[];
  startLabel?: string;
  endLabel?: string;
  key?: string;
  defaultValue?: {
    startDate: string;
    endDate: string;
  };
  dateHistory?: {
    startDate: string | undefined;
    endDate: string | undefined;
    history?: { itemKey: string; itemKeyIndex: number };
  };
}) {
  const [dateSearchType, setDateSearchType] = useState<T>(
    dateSearchTypeOptions[dateHistory?.history?.itemKeyIndex ?? 0].value
  );

  const [startDate, setStartDate] = useState(
    dateHistory?.startDate
      ? dateHistory?.startDate
      : defaultValue?.startDate
      ? defaultValue?.startDate
      : ""
  );

  const [endDate, setEndDate] = useState(
    dateHistory?.endDate
      ? dateHistory?.endDate
      : defaultValue?.endDate
      ? defaultValue?.endDate
      : ""
  );

  const hasValue = useMemo(() => {
    return !!startDate || !!endDate;
  }, [startDate, endDate]);

  const DateSearchPanel = useCallback(() => {
    return (
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          gap: "4px",
          alignSelf: "end",
        }}
      >
        <FormControl className="date-search-type" variant="outlined">
          <InputLabel id="date-search-type-select-label">날짜 타입</InputLabel>

          <Select
            labelId="date-search-type-select-label"
            id="date-search-type-select"
            value={dateSearchType}
            label="날짜 타입"
            defaultValue={dateSearchType}
            onChange={(e) => {
              setDateSearchType(e.target.value as T);
              setStartDate("");
              setEndDate("");
            }}
            size="small"
          >
            {dateSearchTypeOptions.map((st, i) => (
              <MenuItem value={st.value} key={i}>
                {st.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <DatePicker
          label={startLabel || "시작일"}
          when="start"
          value={startDate}
          setDate={(value) => setStartDate(value || "")}
        />

        <Typography className="sads">~</Typography>

        <DatePicker
          label={endLabel || "종료일"}
          when="end"
          value={endDate}
          setDate={(v) => {
            if (!v) {
              setEndDate("");
              return;
            } else if (!startDate) {
              setStartDate(v);
              setEndDate(v);
              return;
            } else if (startDate > v) {
              setEndDate(startDate);
              return;
            }

            setEndDate(v);
          }}
          {...(hasValue
            ? {
                reset: () => {
                  setStartDate("");
                  setEndDate("");
                },
              }
            : {})}
        />

        {startDate && endDate && (
          <Box
            sx={{
              position: "absolute",
              top: "-10px",
              right: "-10px",
              width: "20px",
              height: "20px",
              background: "white",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                setStartDate("");
                setEndDate("");
              }}
              sx={{
                border: "1px solid currentColor",
                borderRadius: "50%",
                width: "16px",
                height: "16px",
              }}
            >
              <ClearIcon sx={{ width: "12px", height: "12px" }} />
            </IconButton>
          </Box>
        )}
      </Box>
    );
  }, [
    dateSearchType,
    dateSearchTypeOptions,
    startLabel,
    startDate,
    endLabel,
    endDate,
    hasValue,
  ]);

  return {
    DateSearchPanel,
    dateSearchType,
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  };
}
