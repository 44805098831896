import { useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";

import Modal from "../../../admin-ui/components/Modal";

export default function useYesOrNoModal({
  title,
  onOk,
  onCancel,
  showsYesOrNoModal,
  setShowsYesOrNoModal,
}: {
  title: string;
  onOk: () => void;
  onCancel: () => void;
  showsYesOrNoModal: boolean;
  setShowsYesOrNoModal: (value: boolean) => void;
}) {
  const yesOrNoModal = useMemo(() => {
    return (
      <Modal
        isOpened={showsYesOrNoModal}
        handleClose={() => setShowsYesOrNoModal(false)}
        modalBody={
          <Grid container spacing={4} direction="column">
            <Grid item>
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            </Grid>

            <Grid container item justifyContent={"center"} spacing={4}>
              <Grid item>
                <Button variant="contained" color="primary" onClick={onOk}>
                  예
                </Button>
              </Grid>

              <Grid item>
                <Button variant="contained" color="error" onClick={onCancel}>
                  아니오
                </Button>
              </Grid>
            </Grid>
          </Grid>
        }
      />
    );
  }, [onCancel, onOk, setShowsYesOrNoModal, showsYesOrNoModal, title]);

  return {
    yesOrNoModal,
  };
}
