import { ChangeEvent, useState } from "react";
import { useQueryClient } from "react-query";
import { useAtomValue } from "jotai";

import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../../jotaiStates/auth";
import ADMIN_BID_USER_QUERY, {
  ADMIN_BID_USER_QUERY_KEY_GEN,
} from "../../../queries/forwarding/admin/ADMIN_BID_USER_QUERY";
import ADMIN_TEAM_QUERY, {
  ADMIN_TEAM_QUERY_KEY_GEN,
} from "../../../queries/forwarding/admin/ADMIN_TEAM_QUERY";
import useSnackbar from "../useSnackbar";

export default function useUserAndTeamCommentList({
  userOrTeamId,
  pageType,
}: {
  userOrTeamId: number;
  pageType: "user" | "team";
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const {
    mutate: updateUserComments,
    ResponseHandler: ResponseHandlerOfUpdateUserComments,
  } = ADMIN_BID_USER_QUERY.useUpdateComment();

  const { mutate: updateTeamComment } = ADMIN_TEAM_QUERY.useUpdateTeamComment();

  const [comment, setComment] = useState("");

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const handleCommentInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setComment(e.target.value);
  };

  const commentId = currentAdminAuthInfo?.accountId || "-";

  const commonPayload = {
    id: commentId,
    comment,
    date: new Date().toISOString(),
  };

  const commonHandler = {
    onSuccess: () => {
      setComment("");
      handleSnackbarOpen("댓글을 등록했습니다.");
      queryClient.invalidateQueries(
        pageType === "user"
          ? ADMIN_BID_USER_QUERY_KEY_GEN.getAdminNewBidUserDetail(userOrTeamId)
          : ADMIN_TEAM_QUERY_KEY_GEN.getAdminTeamDetail(userOrTeamId)
      );
    },
    onError: () => {
      handleSnackbarOpen("요청에 실패했습니다.", "error");
    },
  };

  const handleCommentUpdate = () => {
    if (pageType === "user") {
      updateUserComments(
        {
          pathParams: {
            userId: userOrTeamId,
          },
          ...commonPayload,
        },
        {
          ...commonHandler,
        }
      );

      return;
    }
    updateTeamComment(
      {
        pathParams: {
          teamId: userOrTeamId,
        },
        ...commonPayload,
      },
      {
        ...commonHandler,
      }
    );
  };

  return {
    handleCommentUpdate,
    comment,
    handleCommentInputChange,
    ResponseHandler: <>{ResponseHandlerOfUpdateUserComments}</>,
  };
}
