import { useMemo } from "react";
import { useAtomValue } from "jotai";

import { ForwardingAdminUserListItem } from "../../types/forwarding/adminUser";

import { IS_UNDER_PRODUCTION } from "../../constants";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "../../jotaiStates/auth";

/**
 * 포워딩 매니저 필터 리스트와 현재 접속한 어드민 유저의 id를 리턴하는 커스텀 훅
 * - 포워딩 매니저 필터 리스트를 상수로 관리하고 있었으나 추가나 제거를 계속 반복해야하는 문제
 * - 현재 사용은 영엽관리 > 현황, 프로모션 관리이고 기본값은 석균님의 아이디
 */
function useForwardingManagerAdminUser({
  adminUserList,
}: {
  adminUserList: ForwardingAdminUserListItem[] | undefined;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const forwardingManagerAdminList = useMemo(() => {
    if (!adminUserList) return [];

    return adminUserList
      .filter((adminUser) => adminUser.isForwardingManager)
      .map((forwardingAdminUser) => {
        return {
          label: forwardingAdminUser.name,
          value: forwardingAdminUser.id,
        };
      });
  }, [adminUserList]);

  const forwardingManagerAdminId = useMemo(() => {
    if (!adminUserList) return;

    const currentAdminUserInfo = adminUserList
      ?.filter((n: ForwardingAdminUserListItem) => {
        return n.isForwardingManager;
      })
      .find((v: ForwardingAdminUserListItem) => {
        return v.accountId === currentAdminAuthInfo?.accountId;
      });

    if (currentAdminUserInfo) {
      return currentAdminUserInfo.id;
    }

    if (
      // dev인지 prod 환경인지 확인 후 운영팀 대표자 석균님의 id를 반환
      !IS_UNDER_PRODUCTION
    ) {
      return 46;
    }
    return 10;
  }, [adminUserList, currentAdminAuthInfo?.accountId]);

  return { forwardingManagerAdminId, forwardingManagerAdminList };
}

export default useForwardingManagerAdminUser;
