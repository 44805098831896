import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";

import { Country, Port } from "../../types/common/common";
import { changePortIdToPortName } from "../../utils/forwarding/admin/port";

import ADMIN_COMMON_QUERY from "../../queries/forwarding/admin/ADMIN_COMMON_QUERY";

export interface PortNameListForAutoComplete {
  label: string;
  value: number | null;
}

export interface CountryNameListForAutoComplete {
  label: string;
  value: string | null;
}

export default function TemplateTableAutoCompletes({
  countryData,
  defaultValue,
}: {
  countryData: Country[] | undefined;
  defaultValue?: {
    countryName: string;
    polId: number | undefined;
    podId: number | undefined;
  };
}) {
  const [countryName, setCountryName] = useState(
    defaultValue?.countryName ?? ""
  );
  const [polId, setPolId] = useState<number | undefined>(
    defaultValue?.polId ?? undefined
  );
  const [podId, setPodId] = useState<number | undefined>(
    defaultValue?.podId ?? undefined
  );

  const { data: portData } = ADMIN_COMMON_QUERY.useGetPortList();

  const countryNameListForAutoComplete = useMemo(() => {
    if (!countryData) {
      return [];
    }

    const countryNameList: CountryNameListForAutoComplete[] = countryData?.map(
      (country) => {
        return {
          label: country.nameKR,
          value: country.name,
        };
      }
    );

    return countryNameList.concat([{ label: "", value: null }]);
  }, [countryData]);

  //국가 선택에 따라서 필터가 걸림
  const getPolNameListForAutoComplete = useCallback(
    (countryName) => {
      if (!portData) {
        return [];
      }

      const portNameList: PortNameListForAutoComplete[] = portData
        ?.filter((port: Port) => {
          if (countryName) {
            return port.country === countryName;
          }
          return port;
        })
        .map((port) => {
          return {
            label: `${port.nameEN}(${
              port.name ? port.name : port.nameKR || "-"
            }/ ${port.code})`,
            value: port.id,
          };
        });

      return portNameList.concat([{ label: "", value: null }]);
    },
    [portData]
  );

  const podNameListForAutoComplete = useMemo(() => {
    if (!portData) {
      return [];
    }

    const portNameList: PortNameListForAutoComplete[] = portData.map((port) => {
      return {
        label: `${port.nameEN}(${port.name ? port.name : port.nameKR || "-"}/ ${
          port.code
        })`,
        value: port.id,
      };
    });

    return portNameList.concat([{ label: "", value: null }]);
  }, [portData]);

  const getCountryOfPort = useCallback(
    (portId: number | null | undefined) => {
      return (
        portData?.find((port) => {
          return port.id === portId;
        })?.country || ""
      );
    },
    [portData]
  );

  const handleCountryNameChange = useCallback(
    ({
      event,
      selectValue,
    }: {
      event: SyntheticEvent<Element, Event>;
      selectValue: CountryNameListForAutoComplete | null;
    }) => {
      setPolId(undefined);
      return setCountryName(selectValue?.value || "");
    },
    []
  );

  const handlePortNameChange = useCallback(
    ({
      event,
      selectValue,
      type,
    }: {
      event: SyntheticEvent<Element, Event>;
      selectValue: PortNameListForAutoComplete | null;
      type: "pol" | "pod";
    }) => {
      if (type === "pol") {
        setCountryName(getCountryOfPort(selectValue?.value));
        setPolId(selectValue?.value || undefined);
        return;
      }

      return setPodId(selectValue?.value || undefined);
    },
    [getCountryOfPort]
  );

  const resetData = () => {
    if (!countryName && !polId && !podId) return;
    setCountryName("");
    setPolId(undefined);
    setPodId(undefined);
  };

  const TemplateTableAutoCompletes = useMemo(() => {
    return (
      <Box sx={{ display: "flex", gap: "4px" }}>
        <Box>
          <Autocomplete
            size="small"
            onChange={(
              event,
              newValue: CountryNameListForAutoComplete | null
            ) =>
              handleCountryNameChange({
                event,
                selectValue: newValue,
              })
            }
            value={{
              label:
                countryData?.find((v) => {
                  return v.name === countryName;
                })?.nameKR || countryName,
              value: !countryName ? null : countryName,
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            options={countryNameListForAutoComplete}
            renderInput={(params) => <TextField {...params} label="국가" />}
            renderOption={(props, option) => {
              return (
                <Typography
                  {...props}
                  key={option.value}
                  sx={[
                    {
                      "&:hover": {
                        backgroundColor: blue[50],
                      },
                      maxWidth: 200,
                    },
                  ]}
                >
                  {option.label}
                </Typography>
              );
            }}
            noOptionsText="결과가 없습니다."
            ListboxComponent={(props) => <Box {...props}></Box>}
          />
        </Box>

        <Box>
          <Autocomplete
            size="small"
            onChange={(event, newValue: PortNameListForAutoComplete | null) =>
              handlePortNameChange({
                event,
                selectValue: newValue,
                type: "pol",
              })
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={{
              label: !polId
                ? ""
                : changePortIdToPortName({ portData, portId: polId }),
              value: !polId ? null : polId,
            }}
            options={getPolNameListForAutoComplete(countryName)}
            sx={{ width: 200 }}
            renderInput={(params) => <TextField {...params} label="POL" />}
            renderOption={(props, option) => {
              return (
                <Typography
                  {...props}
                  key={option.value}
                  sx={[
                    {
                      "&:hover": {
                        backgroundColor: blue[50],
                      },
                      maxWidth: 200,
                    },
                  ]}
                >
                  {option.label}
                </Typography>
              );
            }}
            noOptionsText="결과가 없습니다."
            ListboxComponent={(props) => <Box {...props}></Box>}
          />
        </Box>

        <Box>
          <Autocomplete
            size="small"
            onChange={(event, newValue: PortNameListForAutoComplete | null) =>
              handlePortNameChange({
                event,
                selectValue: newValue,
                type: "pod",
              })
            }
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={{
              label: !podId
                ? ""
                : changePortIdToPortName({ portData, portId: podId }),
              value: !podId ? null : podId,
            }}
            options={podNameListForAutoComplete}
            sx={{ width: 200 }}
            renderInput={(params) => (
              <TextField key={params.id} {...params} label="POD" />
            )}
            renderOption={(props, option) => {
              return (
                <Typography
                  {...props}
                  key={option.value}
                  sx={[
                    {
                      "&:hover": {
                        backgroundColor: blue[50],
                      },
                      maxWidth: 200,
                    },
                  ]}
                >
                  {option.label}
                </Typography>
              );
            }}
            noOptionsText="결과가 없습니다."
            ListboxComponent={(props) => <Box {...props}></Box>}
          />
        </Box>
      </Box>
    );
  }, [
    countryData,
    countryName,
    countryNameListForAutoComplete,
    portData,
    polId,
    getPolNameListForAutoComplete,
    podId,
    podNameListForAutoComplete,
    handleCountryNameChange,
    handlePortNameChange,
  ]);

  return {
    TemplateTableAutoCompletes,
    countryName,
    polId,
    podId,
    portData,
    resetData,
  };
}
