import { useMemo } from "react";
import { useCallback, useState } from "react";

import { checkEqualObject } from "../../utils/common/etc";

/**
 * 객체를 가진 Set(집합)을 state로 사용할수 있 게 해 줌
 */
export default function useObjectSet<T extends string | number, K>(
  initialValue?: Record<T, K>[]
) {
  const [set, setSet] = useState(new Set(initialValue));

  const addToSet = useCallback(
    (val: Record<T, K>) => {
      const newSet = new Set(set);

      newSet.add(val);

      setSet(newSet);
    },
    [set]
  );

  const deleteFromSet = useCallback(
    (val: Record<T, K>) => {
      const newSet = new Set(set);

      newSet.delete(val);

      setSet(newSet);
    },
    [set]
  );

  const toggleFromSet = useCallback(
    (val: Record<T, K>) => {
      const newSet = new Set(set);

      if (Array.from(newSet).some((v) => checkEqualObject(v, val))) {
        newSet.forEach((item) => {
          if (checkEqualObject(item, val)) {
            newSet.delete(item);
          }
        });
      } else {
        newSet.add(val);
      }

      setSet(newSet);
    },
    [set]
  );

  const initSet = useCallback((values?: Record<T, K>[]) => {
    setSet(new Set(values));
  }, []);

  const array = useMemo(() => {
    if (!set) return [];

    return Array.from(set);
  }, [set]);

  return {
    /**
     * Set 값을 배열로 반환
     */
    array,
    set,
    add: addToSet,
    delete: deleteFromSet,
    toggle: toggleFromSet,
    init: initSet,
  };
}
