import { useCallback, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";

import { useDebounce } from "../../utils/common/hook";

export interface TermSearchType<T extends string> {
  label: string;
  value: T;
  placeHolder?: string;
}

export default function useSearchWithTerm<T extends string>({
  termSearchTypeOptions,
  // 전역값으로 저장하기 위한 검색타입
  historyTermSearchType,
  // 전역값으로 저장하기 위한 검색어
  historySearchTerm,
  resetCurrentPage,
}: {
  termSearchTypeOptions: TermSearchType<T>[];
  historyTermSearchType?: TermSearchType<T>;
  historySearchTerm?: string;
  resetCurrentPage?: () => void;
}) {
  const [termSearchType, setTermSearchType] = useState<
    TermSearchType<T> | undefined
  >(historyTermSearchType ?? termSearchTypeOptions[0]);

  const [searchTerm, setSearchTerm] = useState(historySearchTerm ?? "");

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  /** shipdaAPI에서 searchType이 각 쿼리파람으로 되어있는 경우가 있음 */
  const debouncedSearchTermWithObject = useMemo(() => {
    if (termSearchType && debouncedSearchTerm) {
      return { [termSearchType.value]: debouncedSearchTerm };
    }
  }, [debouncedSearchTerm, termSearchType]);

  const handleTermSearchTypeChange = useCallback(
    (event: SelectChangeEvent) => {
      const target = termSearchTypeOptions.find(
        (st) => st.value === event.target.value
      );

      if (target) {
        setTermSearchType(target);
        setSearchTerm("");
        resetCurrentPage && resetCurrentPage();
      }
    },
    [resetCurrentPage, termSearchTypeOptions]
  );

  const hasValue = useMemo(() => {
    return !!searchTerm;
  }, [searchTerm]);

  const reset = useCallback(
    (e) => {
      e.stopPropagation();
      setSearchTerm("");
      setTermSearchType(termSearchTypeOptions[0]);
      resetCurrentPage && resetCurrentPage();
    },
    [resetCurrentPage, termSearchTypeOptions]
  );

  const handleSearchTermChange = useCallback(
    (e) => {
      setSearchTerm(e.target.value);
      resetCurrentPage && resetCurrentPage();
    },
    [resetCurrentPage]
  );

  const TermSearchPanel = useMemo(() => {
    return (
      <Box
        sx={{
          display: "flex",
        }}
      >
        <FormControl
          className="search-type"
          variant="outlined"
          sx={{ mr: 0.5 }}
        >
          <InputLabel id="search-term-select-label">검색 대상</InputLabel>

          <Select
            labelId="search-term-select-label"
            id="search-term-select"
            value={termSearchType?.value || ""}
            label="검색 대상"
            onChange={handleTermSearchTypeChange}
            size="small"
          >
            {termSearchTypeOptions.map((st, i) => (
              <MenuItem value={st.value} key={i}>
                {st.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box sx={{ display: "flex", gap: "4px" }}>
          <TextField
            label={termSearchType?.placeHolder || "검색어"}
            size="small"
            value={searchTerm}
            onChange={handleSearchTermChange}
            InputProps={{
              endAdornment: hasValue && (
                <InputAdornment position="end">
                  <IconButton onClick={reset}>
                    <CloseIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
    );
  }, [
    termSearchType?.value,
    termSearchType?.placeHolder,
    handleTermSearchTypeChange,
    termSearchTypeOptions,
    searchTerm,
    handleSearchTermChange,
    hasValue,
    reset,
  ]);

  return {
    TermSearchPanel,
    debouncedSearchTerm,
    termSearchType,
    reset,
    debouncedSearchTermWithObject,
    searchTerm,
  };
}
